
// require("dotenv/config");
// Captcha Script Start Here

// Function to generate a random code
function generateCode() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = 6;
  let code = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
}

// Function to create an image from the code
function createCaptchaImage() {
  const code = generateCode();
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 150;
  canvas.height = 50;

  // Customize styles as needed
  context.font = '30px Arial';
  context.fillStyle = '#fff';
  context.fillText(code, 20, 40);

  document.getElementById('captchaImage').innerHTML = '';
  document.getElementById('captchaImage').appendChild(canvas);

  // Store the code in a variable for validation
  window.captchaCode = code;
}

window.onload = createCaptchaImage;



function validateEmail(email) {
  // Check if email is null, undefined, or empty
  if (!email || email.trim() === "") {
      alert("Please add your email Id");
      return false;
  }

  if (!email.includes('@')) {
      alert("Please add '@' in your email");
      return false;
  }

 // Check for ".com" (or any other valid top-level domain)
 if (!email.endsWith(".com")) {
  alert("Email address must end with '.com'. Please enter a valid email address.");
  return false;
}

  // Return true if all validations pass
  return true;
}









// Captcha Script Finish Here
let nameVal = document.getElementById("First_Name");
let emailVal = document.getElementById("Email");
let companyVal = document.getElementById("Company_Name");
let contactVal = document.getElementById("Phone_number");
let messageVal = document.getElementById("Message");
// let attachVal = document.getElementById("attachment");
let capchaVal = document.getElementById("captchaInput");

async function FormSubmit(event) {
  event.preventDefault();

  // debugger;


  // if(nameVal.value === null || nameVal.value === undefined || nameVal.value === "" ){
  //   alert('Please Enter Name')
  //   return false
  // }




  // if(emailVal.value === null || emailVal.value === undefined || emailVal.value === "" ){
  //   alert("Please add your email Id");

  //   return false;

  //   // Check if "@" symbol exists in the email
  //   if (!emailVal.value.includes('@')) {
  //       alert("Please add '@' in your email");
  //       return false; // Return false because validation failed
  //   }
  //   return false;
  // }

// Example usage:



if (validateEmail(emailVal.value)) {
  console.log("Email is valid.");
} else {
  console.log("Email is invalid.");
}


  


  
  
  if (!validateCaptcha()) return
  function validateCaptcha() {
    const userInput = document.getElementById('captchaInput').value;
    if (userInput !== window.captchaCode) {
      alert('Incorrect captcha code. Please try again.')
      createCaptchaImage();
      return false
    }
    createCaptchaImage();
    return true
  }

  
  
  window.onload = createCaptchaImage;
  let submitBtn = document.getElementById("form-button");
  submitBtn.setAttribute("editable", true);
  const formD = new FormData();






 

  if (contactVal.value.length < 9 && contactVal.value.length > 0) {
    alert("Please add at least 9 digits phone number");
    return;
  } else if (contactVal.value.length > 18) {
    alert("Please add maximum 18 digits phone number");
    return;
  } else {
    formD.append("phone", contactVal.value);
  }
  
  formD.append("name", nameVal.value);
  formD.append("email", emailVal.value);
  formD.append("company_name", companyVal.value);
  formD.append("phone", contactVal.value);
  formD.append("description", messageVal.value);
  formD.append("source", "contact-us");


  
  
  
  const options = {
    method: "POST",
    body: formD,
  };
  try {
    const resp = await fetch(
      `${process.env.APIURL}/blog/api/contact-us`,
      options
    );
    window.location.assign("/thankyou");
    nameVal.value = "";
    emailVal.value = "";
    companyVal.value = "";
    contactVal.value = "";
    messageVal.value = "";
    capchaVal.value = "";
    if (!resp.ok) throw Error("Something went wrong");
    document
      .getElementById("form-button")
      .addEventListener("click", function (event) {
        gtag("send", "event", {
          eventCategory: "Contact",
          eventAction: "Information Request",
          eventLabel: "Contact Form",
          hitCallback: function () {
            document.querySelector("form-button").trigger("submit");
          },
        });
      });
    submitBtn.setAttribute("editable", false);
  } catch (error) {
    console.log(error);
  }
}

// function closeConfirmPopup() {
//   confirmPopup.style.display = "none";
// }

// document
//   .getElementById("form-button")
//   .addEventListener("click", function (event) {
//     gtag("send", "event", {
//       eventCategory: "Contact",
//       eventAction: "Information Request",
//       eventLabel: "Contact Form",
//       hitCallback: function () {
//         document.querySelector("form-button").trigger("submit");
//       },
//     });
//   });

window.FormSubmit = FormSubmit
window.generateCode = generateCode
window.createCaptchaImage = createCaptchaImage
